import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import language from "@language"

import Markdown from "markdown-to-jsx"
import { ReactComponent as DCHLogoFooter } from "@images/logos/DCH_Logo_Vertical_WhiteVertical.svg"

import UniversalLink from "@components/common/UniversalLink"
import ErrorRender from "@components/common/ErrorRender"
import { preFilterMarkdown } from "@utilities/markdownUtils"
import SocialIcons from "../common/SocialIcons"
import Newsletterform from "@components/common/NewsletterForm"

import "./style.scss"

const Footer = props => {
  const data = useStaticQuery(graphql`
    {
      wpMenu(name: { eq: "Footer Menu" }) {
        name
        acfFooterFields {
          formSectionTitle
          bottomText
          leftText
          linkGroup1 {
            ... on WpPage {
              id
              uri
              acfPageMeta {
                metaTitle
              }
            }
          }
          linkGroup2 {
            ... on WpPage {
              id
              uri
              acfPageMeta {
                metaTitle
              }
            }
          }
          linkGroup3 {
            ... on WpPage {
              id
              acfPageMeta {
                metaTitle
              }
              uri
            }
          }
          facebookLink {
            title
            url
          }
          instagramLink {
            title
            url
          }
          linkedinLink {
            title
            url
          }
          twitterUrl {
            title
            url
          }
        }
      }
    }
  `)

  if (!data.wpMenu)
    return (
      <ErrorRender message='Error: Please create a menu named "Footer Menu".' />
    )

  return (
    <>
      <Newsletterform title={data.wpMenu.acfFooterFields.formSectionTitle} />
      <footer className="footer">
        <div className="footer__top">
          <div className="page-wrap">
            <div className="footer__top-wrapper ">
              <div className="footer__top-left col-md-4">
                <DCHLogoFooter
                  className="footer__top-left-logo"
                  alt={language[props.language].health_for_life}
                  height="132px"
                />
                <Markdown
                  className="nav-sm"
                  options={{
                    forceInline: true,
                    overrides: {
                      a: {
                        props: {
                          className: "footer__link",
                        },
                      },
                    },
                  }}
                >
                  {preFilterMarkdown(data.wpMenu.acfFooterFields.leftText)}
                </Markdown>
              </div>
              <div className="footer__top-link-section">
                <div className="footer__top-links footer__top-links-1 col-md-3">
                  <p className="footer__title">
                    {language[props.language].visit_dch}
                  </p>
                  {data.wpMenu.acfFooterFields.linkGroup1 &&
                    data.wpMenu.acfFooterFields.linkGroup1.map(
                      (link, index) => {
                        return (
                          <UniversalLink
                            key={`footer-links2--${index}`}
                            href={link.uri}
                            className="footer__link--subdued"
                          >
                            {link.acfPageMeta.metaTitle ? link.acfPageMeta.metaTitle : ''}

                          </UniversalLink>
                        )
                      }
                    )}
                    <UniversalLink
                      className="footer__link--subdued"
                      href="/noticias/price-transparency/"
                      title="Price Transparency"
                    />
                </div>
                <div className="footer__top-links footer__top-links-2 col-md-3">
                  <p className="footer__title">
                    {language[props.language].insitution}
                  </p>
                  {data.wpMenu.acfFooterFields.linkGroup2 &&
                    data.wpMenu.acfFooterFields.linkGroup2.map(
                      (link, index) => {
                        return (
                          <UniversalLink
                            key={`footer-links2--${index}`}
                            href={link.uri}
                            className="footer__link--subdued"
                          >
                            {link.acfPageMeta.metaTitle ? link.acfPageMeta.metaTitle : ''}

                          </UniversalLink>
                        )
                      }
                    )}
                </div>
                <div className="footer__top-links footer__top-links-3 col-md-3">
                  <p className="footer__title">
                    {language[props.language].help}
                  </p>
                  {data.wpMenu.acfFooterFields.linkGroup3 &&
                    data.wpMenu.acfFooterFields.linkGroup3.map(
                      (link, index) => {
                        return (
                          <UniversalLink
                            key={`footer-links2--${index}`}
                            href={link.uri}
                            className="footer__link--subdued"
                          >
                            {link.acfPageMeta.metaTitle ? link.acfPageMeta.metaTitle : ''}

                          </UniversalLink>
                        )
                      }
                    )}
                  {data.wpMenu && (
                    <SocialIcons
                      className="footer__social-icons"
                      facebookUrl={
                        (data.wpMenu.acfFooterFields.facebookLink || {}).url
                      }
                      instagramUrl={
                        (data.wpMenu.acfFooterFields.instagramLink || {}).url
                      }
                      linkedinUrl={
                        (data.wpMenu.acfFooterFields.linkedinLink || {}).url
                      }
                      twitterUrl={
                        (data.wpMenu.acfFooterFields.twitterUrl || {}).url
                      }
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer__bottom-row">
          <div className="footer__bottom-row-wrapper page-wrap">
            <Markdown
              className="nav-xs"
              options={{
                forceInline: true,
                overrides: {
                  a: {
                    component: UniversalLink,
                    props: {
                      className: "footer__link",
                    },
                  },
                },
              }}
            >
              {preFilterMarkdown(data.wpMenu.acfFooterFields.bottomText)}
            </Markdown>
          </div>
        </div>
      </footer>
    </>
  )
}

Footer.propTypes = {
  language: PropTypes.string,
}

Footer.defaultProps = {
  language: "es",
}

export default Footer
