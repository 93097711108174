// https://dev.to/nevernull/setup-menu-navigation-guide-to-gatsby-wordpress-starter-advanced-with-previews-i18n-and-more-3bfl#create-wordpress-menu-globewithmeridians

import React from "react"
import PropTypes from "prop-types"
import { Link as GatsbyLink } from "gatsby"
// Since DOM elements <a> cannot receive activeClassName
// and partiallyActive, destructure the prop here and
// pass it only to GatsbyLink
const UniversalLink = props => {
  // We're going to use this list to enforce relative links
  // because wordpress's absolute URLS are stupid and don't understand how environments work.
  // and WP hosts are stupid for disabling the ability to change the site url in WP settings.
  const HOSTS_TO_SCRUB = [
    "https://staging-doctors-center-hospital.mystagingwebsite.com/",
    "https://doctors-center-hospital.mystagingwebsite.com/",
  ]

  const path = props.href || props.to || ""

  const createRelativeLink = (href = "") => {
    if (!href) return href
    HOSTS_TO_SCRUB.forEach(host => {
      href = href.replace(host, "")
    })

    return href
  }

  // Assumes that any internal link (intended for Gatsby)
  // will start with exactly one slash or a #, and that anything else is external.
  const link = createRelativeLink(path)
  const internal = /^\/(?!\/)/.test(link) || path.charAt(0) === "#"
  // Use Gatsby Link for internal links, and <a> for others
  if (internal) {
    return (
      <GatsbyLink
        to={link}
        className={props.className}
        activeClassName={props.activeClassName}
        partiallyActive={props.partiallyActive}
        onClick={props.onClick}
        role={props.role}
        tabIndex={props.tabIndex}
        ariaLabel={props.ariaLabel}
      >
        {props.title || props.children}
      </GatsbyLink>
    )
  } else if (link) {
    return (
      <a
        href={link}
        className={props.className}
        target="_blank"
        rel="noopener noreferrer"
        onClick={props.onClick}
        role={props.role}
        tabIndex={props.tabIndex}
        aria-label={props.ariaLabel}
      >
        {props.title || props.children}
      </a>
    )
  } else {
    return (
      <span
        className={props.className}
        onClick={props.onClick}
        role={props.role}
        tabIndex={props.tabIndex}
        aria-label={props.ariaLabel}
      >
        {props.title || props.children}
      </span>
    )
  }
}

UniversalLink.propTypes = {
  href: PropTypes.string,
  to: PropTypes.string,
  tabIndex: PropTypes.string,
  role: PropTypes.string,
  ariaLabel: PropTypes.string,
}

export default UniversalLink
