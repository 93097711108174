import React, { useEffect } from "react"
import { ReactComponent as XCloseBlue } from "@images/icons/x-icon-blue.svg"
import "./style.scss"




const BannerMessage = () => {
  
  useEffect(() => {

    let bannerMessage = document.querySelector('.banner-msg');
    let close = bannerMessage.querySelector('button')

    let bannerHidden = "true"
    bannerHidden = sessionStorage.getItem("bannerMessageDismissed");

    if(bannerHidden !== "true"){
      bannerMessage.classList.remove('hidden')
    }




    close.addEventListener('click', () => {
      sessionStorage.setItem("bannerMessageDismissed", "true");
      bannerMessage.classList.add('hidden')
    })

  }, [])

  // const setBannerMessageDismissed = () => {
  //   // sessionStorage.setItem("bannerMessageDismissed", 'true');
  //   setBannerHidden(true);
  // }

  // // let bannerMessageDismissed = sessionStorage.getItem("bannerMessageDismissed");
  // const [bannerHidden, setBannerHidden] = useState(false)

  return (
      <div className="banner-msg hidden">
        <p>Actualización incidente de seguridad 12/15/2022. Comunicado <a href="https://doctorscenterhospital.com/noticias/actualizacion-incidente-de-seguridad/" target="_blank">aquí.</a> Recursos adicionales <a href="https://response.idx.us/doctorscenterhospital" target="_blank">aquí.</a></p>
        <button aria-label="close">
          <XCloseBlue />
        </button>
      </div>
  )
}

export default BannerMessage
