import React from "react"
import PropTypes from "prop-types"
import BrandButton from "../BrandButton"
import classnames from "classnames"

import { ReactComponent as FacebookIcon } from "@images/icons/facebook-icon.svg"
import { ReactComponent as InstagramIcon } from "@images/icons/instagram-icon.svg"
import { ReactComponent as LinkedinIcon } from "@images/icons/linkedin-icon.svg"
import { ReactComponent as TwitterIcon } from "@images/icons/twitter-icon.svg"

import "./style.scss"

const SocialIcons = props => {
  return (
    <div className={classnames("social-icons", props.className)}>
      {props.instagramUrl && (
        <BrandButton
          className="social-icons__icon"
          size="utility"
          color="primary"
          btnStyle="fill"
          icon={<InstagramIcon style={{ height: "20px" }} />}
          href={props.instagramUrl}
          ariaLabel="Instagram"
        />
      )}
      {props.facebookUrl && (
        <BrandButton
          className="social-icons__icon"
          size="utility"
          color="primary"
          btnStyle="fill"
          icon={<FacebookIcon style={{ height: "20px" }} />}
          href={props.facebookUrl}
          ariaLabel="Facebook"
        />
      )}
      {props.twitterUrl && (
        <BrandButton
          className="social-icons__icon"
          size="utility"
          color="primary"
          btnStyle="fill"
          icon={<TwitterIcon style={{ height: "20px" }} />}
          href={props.twitterUrl}
          ariaLabel="Twitter"
        />
      )}
      {props.linkedinUrl && (
        <BrandButton
          className="social-icons__icon"
          size="utility"
          color="primary"
          btnStyle="fill"
          icon={<LinkedinIcon style={{ height: "20px" }} />}
          href={props.linkedinUrl}
          ariaLabel="Linkedin"

        />
      )}
    </div>
  )
}

SocialIcons.propTypes = {
  facebookUrl: PropTypes.string,
  instagramUrl: PropTypes.string,
  linkedinUrl: PropTypes.string,
}
SocialIcons.defaultProps = {}

export default SocialIcons
