import he from "he"

export const preFilterMarkdown = rawMarkdown => {
  if (!rawMarkdown) return rawMarkdown
  let markdown = rawMarkdown
  markdown = replaceMarkdownTokens(markdown)
  markdown = he.decode(markdown)
  return markdown
}

export const replaceMarkdownTokens = rawMarkdown => {
  if (!rawMarkdown) return rawMarkdown
  return rawMarkdown.replace("{{ current_year }}", new Date().getFullYear())
}
