import React from "react"
import { Location } from "@reach/router"
import queryString from "query-string"

export const withLocation = ComponentToWrap => props => {
  return (
    <Location>
      {({ location, navigate }) => (
        <ComponentToWrap
          {...props}
          browserLocation={location}
          navigate={navigate}
          search={location.search ? queryString.parse(location.search) : {}}
        />
      )}
    </Location>
  )
}

// https://reedbarger.com/how-to-create-a-usewindowsize-react-hook/
export const useWindowSize = () => {
  const isSSR = typeof window !== "undefined"
  const [windowSize, setWindowSize] = React.useState({
    width: isSSR ? 1200 : typeof window !== "undefined" && window.innerWidth,
    height: isSSR ? 800 : typeof window !== "undefined" && window.innerHeight,
  })

  function changeWindowSize() {
    setWindowSize({ width: window.innerWidth, height: window.innerHeight })
  }

  React.useEffect(() => {
    changeWindowSize()
    window.addEventListener("resize", changeWindowSize)

    return () => {
      window.removeEventListener("resize", changeWindowSize)
    }
  }, [])

  return windowSize
}

export const mqSm = () => {
  return typeof window !== "undefined" && window.innerWidth >= 576
}

export const mqMd = () => {
  return typeof window !== "undefined" && window.innerWidth >= 768
}

export const mqLg = () => {
  return typeof window !== "undefined" && window.innerWidth >= 992
}

export const mqXl = () => {
  return typeof window !== "undefined" && window.innerWidth >= 1200
}
