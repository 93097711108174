import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import { slide as Menu } from "react-burger-menu"

import language from "@language"
import BodyText from "../common/BodyText"

import XIconWhite from "@images/icons/x-icon-white.svg"
import BrandButton from "../common/BrandButton"
import NavLogo from "../Header/NavLogo"
import UniversalLink from "@components/common/UniversalLink"

import { mqMd, mqXl } from "@utilities/componentUtils"

import "./style.scss"
import PortalButton from "../common/PortalButton"

const NavMenu = props => {
  const [menuWidth, setMenuWidth] = useState("100%")

  useEffect(() => {
    if (mqXl()) {
      setMenuWidth("800px")
    } else if (mqMd()) {
      setMenuWidth("80%")
    } else {
      setMenuWidth("100%")
    }
  }, [])

  const query = graphql`
    {
      wpMenu(name: { eq: "Header Menu" }) {
        name
        acfHeaderMenuFields {
          fieldGroupName
          primaryLinks {
            ... on WpPage {
              id
              uri
              acfPageMeta {
                metaTitle
              }
            }
          }
          secondaryLinks {
            ... on WpPage {
              id
              uri
              acfPageMeta {
                metaTitle
              }
            }
          }
          primaryCta {
            title
            url
          }
          facebookLink {
            url
            title
          }
          instagramLink {
            url
            title
          }
          linkedinLink {
            url
            title
          }
          twitterUrl {
            title
            url
          }
        }
      }
    }
  `

  return (
    <StaticQuery
      query={query}
      render={data => {
        return (
          <Menu
            className="nav-menu"
            overlayClassName="nav-menu__overlay"
            right
            tabIndex={props.isOpen ? "0" : "-1"}
            width={menuWidth}
            isOpen={props.isOpen}
            customBurgerIcon={false}
            customCrossIcon={false}
            onClose={() => props.setNavMenuOpen(false)}
            styles={{
              bmOverlay: {
                background: "rgba(0, 0, 0, 0.6)",
              },
            }}
          >
            <div className="nav-menu__top-bar" aria-modal="true">
              <div className="nav-menu__top-bar-wrapper">
                <NavLogo />
                <div className="nav-menu__top-bar-right-items">
                  {/* <BrandButton
                    className="nav-menu__cta"
                    size="nav"
                    color="secondary"
                    btnStyle="fill"
                    href={data.wpMenu.acfHeaderMenuFields.primaryCta.url}
                    text={data.wpMenu.acfHeaderMenuFields.primaryCta.title}
                    tabIndex={props.isOpen ? "0" : "-1"}
                  /> */}
                  <BrandButton
                    className="nav-menu__close"
                    size="utility"
                    ariaLabel={language[props.language].close_menu}
                    color="primary"
                    btnStyle="fill"
                    icon={<img alt="" src={XIconWhite} />}
                    onClick={() => props.setNavMenuOpen(false)}
                    onKeyDown={() => {}}
                    tabIndex={props.isOpen ? "0" : "-1"}
                  />
                </div>
              </div>
            </div>
            <ul
              className="nav-menu__content nav-menu__primary-menu-links"
              aria-label={`${language[props.language].navigation_links} 1`}
              tabIndex={props.isOpen ? "0" : "-1"}
            >
              <li className="li-reset">
                <PortalButton />
              </li>
              {data.wpMenu.acfHeaderMenuFields.primaryLinks.map(
                (link, index) => {
                  return (
                    <li className="li-reset">
                      <UniversalLink
                        key={`nav-menu__primary-menu-links--${index}`}
                        className="nav-menu__primary-item text-link text-link--green-hover"
                        href={link.uri}
                        title={link.acfPageMeta.metaTitle}
                        tabIndex={props.isOpen ? "0" : "-1"}
                      />
                    </li>
                  )
                }
              )}
            </ul>
            <ul
              className="nav-menu__content nav-menu__secondary-menu-links"
              aria-label={`${language[props.language].navigation_links} 2`}
              tabIndex={props.isOpen ? "0" : "-1"}
            >
              {data.wpMenu.acfHeaderMenuFields.secondaryLinks.map(
                (link, index) => {
                  return (
                    <li className="li-reset">
                      <UniversalLink
                        key={`nav-menu__secondary-menu-links--${index}`}
                        className="nav-menu__secondary-item text-link text-link--green-hover"
                        href={link.uri}
                        title={link.acfPageMeta.metaTitle}
                        tabIndex={props.isOpen ? "0" : "-1"}
                      />
                    </li>
                  )
                }
              )}
            </ul>
            {(data.wpMenu.facebookLink ||
              data.wpMenu.instagramLink ||
              data.wpMenu.linkedinLink ||
              data.wpMenu.twitterUrl) && (
              <ul
                className="nav-menu__content nav-menu__social-links"
                aria-label={`${language[props.language].navigation_links} 3`}
                tabIndex={props.isOpen ? "0" : "-1"}
              >
                {data.wpMenu.facebookLink && (
                  <li className="li-reset">
                    <UniversalLink
                      className="nav-menu__social-item text-link"
                      href={data.wpMenu.facebookLink.url}
                      title={data.wpMenu.facebookLink.title}
                      tabIndex={props.isOpen ? "0" : "-1"}
                    />
                  </li>
                )}

                {data.wpMenu.instagramLink && (
                  <li className="li-reset">
                    <UniversalLink
                      className="nav-menu__social-item text-link"
                      href={data.wpMenu.instagramLink.url}
                      title={data.wpMenu.instagramLink.title}
                      tabIndex={props.isOpen ? "0" : "-1"}
                    />
                  </li>
                )}
                {data.wpMenu.linkedinLink && (
                  <li className="li-reset">
                    <UniversalLink
                      className="nav-menu__social-item text-link"
                      href={data.wpMenu.linkedinLink.url}
                      title={data.wpMenu.linkedinLink.title}
                      tabIndex={props.isOpen ? "0" : "-1"}
                    />
                  </li>
                )}
                {data.wpMenu.twitterUrl && (
                  <li className="li-reset">
                    <UniversalLink
                      className="nav-menu__social-item text-link"
                      href={data.wpMenu.twitterUrl.url}
                      title={data.wpMenu.twitterUrl.title}
                      tabIndex={props.isOpen ? "0" : "-1"}
                    />
                  </li>
                )}
              </ul>
            )}
            <a
              href="#main"
              onClick={() => props.setNavMenuOpen(false)}
              className="sr-only sr-only-focusable skip-to"
            >
              <BodyText size="md" color="dark">
                {language[props.language].skip_to_main_content}
              </BodyText>
            </a>
          </Menu>
        )
      }}
    />
  )
}

NavMenu.propTypes = {
  isOpen: PropTypes.bool,
  setNavMenuOpen: PropTypes.func,
  language: PropTypes.string,
}
NavMenu.defaultProps = {
  isOpen: false,
  language: "es",
}

export default NavMenu
