import React from "react"
import PropTypes from "prop-types"
import classnames from "classnames"

import "./style.scss"

const FormInput = props => {
  return (
    <input
      className={classnames(
        "form-input",
        props.className,
        `form-input--on-${props.inputType}`
      )}
      ref={props.inputRef}
      name={props.name}
      id={props.id}
      placeholder={props.placeholder}
      style={props.style}
      type={props.type}
      aria-hidden={props.ariaHidden}
      value={props.value}
      onChange={props.onChange}
      required={props.required}
      tabIndex={props.tabIndex}
    />
  )
}

FormInput.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  placeholder: PropTypes.string,
  inputRef: PropTypes.object,
  style: PropTypes.object,
  type: PropTypes.string,
  ariaHidden: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  tabIndex: PropTypes.string,
  inputType: PropTypes.oneOf(["light", "dark"]),
}
FormInput.defaultProps = {
  type: "text",
  inputType: "light",
}

export default FormInput
