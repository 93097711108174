import React from "react"
import PropTypes from "prop-types"

import classnames from "classnames"

import "./style.scss"

const validElements = ["h1", "h2", "h3", "h4", "p"]

const HeadingText = props => {
  const validHeadingLevels = validElements

  const safeHeading = props.element ? props.element.toLowerCase() : ""
  const HeadingElement = validHeadingLevels.includes(safeHeading)
    ? safeHeading
    : "p"

  return (
    <HeadingElement
      className={classnames(
        "heading-text",
        `heading-text--${props.size}`,
        `heading-text--${props.color}`,
        { "font-weight--normal": props.fontWeight === "normal" },
        { "font-weight--300": props.fontWeight === "300" },
        props.className
      )}
      style={props.style}
    >
      {props.text || props.children}
    </HeadingElement>
  )
}

HeadingText.propTypes = {
  className: PropTypes.string,
  color: PropTypes.oneOf([
    "dark",
    "dark-gray",
    "light",
    "primary",
    "primary-light",
    "primary-dark",
  ]),
  element: PropTypes.oneOf(validElements),
  text: PropTypes.string,
  size: PropTypes.oneOf([
    "xxl",
    "xxl",
    "xl",
    "xl2",
    "lg",
    "lg2",
    "lgr",
    "md",
    "md2",
    "md3",
    "sm",
    "sm2",
    "xs",
    "xxxs",
  ]),
  fontWeight: PropTypes.oneOf(["300", "normal"]),

  style: PropTypes.object,
}
HeadingText.defaultProps = {
  color: "dark",
  element: "h2",
  size: "xl",
}

export default HeadingText
