import React from "react"
import PropTypes from "prop-types"
import HeadingText from "@components/common/HeadingText"
import EmailForm from "@components/common/EmailForm"

import "./style.scss"

const Newsletterform = props => {
  return (
    <div></div>
    /*<div className="newsletter-form content-spacing--md">
      <div className="newsletter-form__wrapper page-wrap">
        <div className="row">
          <div className="col-md-6">
            <HeadingText
              className="newsletter-form__title"
              text={props.title}
              color="light"
              size="lg2"
            />
          </div>
          <div className="newsletter-form__left col-md-6">
            <EmailForm language="es" />
          </div>
        </div>
      </div>
    </div> */
  )
}

Newsletterform.propTypes = {
  title: PropTypes.string,
}
Newsletterform.defaultProps = {}

export default Newsletterform
