import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { StaticQuery, graphql } from "gatsby"

import language from "@language"

import classnames from "classnames"

import { ReactComponent as HamburgerMenuCircledWhite } from "@images/icons/hamburger-menu-circled--white.svg"
import BrandButton from "@components/common/BrandButton"
import PortalButton from "@components/common/PortalButton"

import NavLogo from "./NavLogo"
import UniversalLink from "@components/common/UniversalLink"
import ErrorRender from "@components/common/ErrorRender"

import "./style.scss"
import BannerMessage from "../BannerMessage"

const HideBanner = React.createContext(false);



const Header = props => {


  const [isScrollTop, setIsScrollTop] = useState(true)


  const query = graphql`
    {
      wpMenu(name: { eq: "Header Menu" }) {
        name
        acfHeaderMenuFields {
          fieldGroupName
          primaryLinks {
            ... on WpPage {
              id
              uri
              acfPageMeta {
                metaTitle
              }
            }
          }
          secondaryLinks {
            ... on WpPage {
              id
              uri
              acfPageMeta {
                metaTitle
              }
            }
          }
          primaryCta {
            title
            url
          }
        }
      }
    }
  `

  const showScrollTopStyle = (isHomepage, isScrollTop) => {
    return isHomepage && isScrollTop
  }

  
  useEffect(() => {
    



    if (!(props.layoutRef || props.layoutRef.current)) return

    const element = props.layoutRef.current

    const handleScroll = () => {
      setIsScrollTop(element.scrollTop <= 20)
    }

    element.addEventListener("scroll", handleScroll)
    return () => element.removeEventListener("scroll", handleScroll)
  }, [props.layoutRef])

  return (
    <StaticQuery
      query={query}
      render={data => {
        if (!data.wpMenu)
          return (
            <ErrorRender message='Error: Please create a menu named "Header Menu".' />
          )

        return (
          <header
            className={classnames("header", {
              "header--top": showScrollTopStyle(props.isHomepage, isScrollTop),
            })}
          >
            <HideBanner.Provider value={false}>
              <BannerMessage />
            </HideBanner.Provider>

            <div className="header__wrapper">
              <div className="header__left-items">
                <NavLogo
                  className="header__logo text-link"
                  href="/"
                  // label={props.siteTitle}
                  // subLabel={"de Puerto Rico"}
                  colorStyle={
                    showScrollTopStyle(props.isHomepage, isScrollTop)
                      ? "white"
                      : "two-color"
                  }
                />
              </div>
              <ul
                className="header__right-items"
                aria-label={language[props.language].navigation_links}
              >
                {data.wpMenu.acfHeaderMenuFields.primaryLinks.map(
                  (link, index) => {
                    return (
                      <li className="li-reset">
                        <UniversalLink
                          key={`header__right-items--${index}`}
                          className="header__item-link"
                          href={link.uri}
                          title={link.acfPageMeta.metaTitle}
                        />
                      </li>
                    )
                  }
                )}
                <li className="li-reset">
                  <UniversalLink
                    className="header__item-link"
                    href="/noticias/price-transparency/"
                    title="Price Transparency"
                  />
                </li>
                 <li className="li-reset">
                  <UniversalLink
                    className="header__item-link"
                    href="/insurancecontracts/"
                    title="Contrato con Aseguradoras"
                  />
                </li>
                <li className="li-reset">
                  <PortalButton />
                </li>
                <li className="li-reset">
                  <button
                    className="header__hamburger-button"
                    onClick={() => props.setNavMenuOpen(true)}
                    aria-label={language[props.language].open_menu}
                  >
                    <HamburgerMenuCircledWhite
                      className={classnames("header__hamburger", {
                        "header__hamburger--scrolled": !showScrollTopStyle(
                          props.isHomepage,
                          isScrollTop
                        ),
                        "border-white": showScrollTopStyle(
                          props.isHomepage,
                          isScrollTop
                        ),
                      })}
                    />
                  </button>
                </li>
              </ul>
            </div>
          </header>
        )
      }}
    />
  )
}

Header.propTypes = {
  layoutRef: PropTypes.object,
  siteTitle: PropTypes.string,
  setNavMenuOpen: PropTypes.func,
  isHomepage: PropTypes.bool,
  language: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: "",
  isHomepage: false,
  language: "es",
}

export default Header
