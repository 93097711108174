import React from "react"
import PropTypes from "prop-types"
import { ReactComponent as DCHIconWhite } from "@images/logos/DCH_Logo_Horizontal_White.svg"
import { ReactComponent as DCHIcon2Color } from "@images/logos/DCH_Logo_Horizontal_Color.svg"

import classnames from "classnames"
import UniversalLink from "@components/common/UniversalLink"

import "./style.scss"

const NavLogo = props => {
  return (
    <UniversalLink
      to={props.href}
      className={classnames("nav-logo", props.className)}
    >
      {props.colorStyle === "white" && (
        <DCHIconWhite className="nav-logo__image" />
      )}
      {props.colorStyle === "two-color" && (
        <DCHIcon2Color className="nav-logo__image" />
      )}
      <div>
        {props.label && <p className="nav-logo__label">{props.label}</p>}
        {props.subLabel && <p className="nav-logo__sublabel">{props.subLabel}</p>}
      </div>

    </UniversalLink>
  )
}

NavLogo.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  href: PropTypes.string,
  colorStyle: PropTypes.oneOf(["white", "two-color"]),
}
NavLogo.defaultProps = {
  colorStyle: "two-color",
}

export default NavLogo
