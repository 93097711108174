import React from "react"
import PropTypes from "prop-types"
import classnames from "classnames"
import UniversalLink from "@components/common/UniversalLink"

import "./style.scss"

const BrandButton = props => {
  const BodyElement = props.element ? props.element.toLowerCase() : "button"

  if (props.href) {
    return (
      <UniversalLink
        className={classnames(
          props.className,
          "brand-button",
          "btn",
          `btn-${props.size}`,
          `btn-${props.color}`,
          `btn-${props.btnStyle}`
        )}
        to={props.href}
        onClick={props.onClick}
        onKeyDown={props.onKeyDown}
        role={props.role}
        tabIndex={props.tabIndex}
        ariaLabel={props.ariaLabel}
        ariaHidden={props.ariaHidden}
      >
        {props.icon && <span className="brand-button__icon">{props.icon}</span>}
        {props.text && <span className="brand-button__text">{props.text}</span>}
      </UniversalLink>
    )
  } else {
    return (
      <BodyElement
        className={classnames(
          props.className,
          "brand-button",
          "btn",
          `btn-${props.size}`,
          `btn-${props.color}`,
          `btn-${props.btnStyle}`
        )}
        disabled={props.disabled}
        onClick={props.onClick}
        onKeyDown={props.onKeyDown}
        type={props.type}
        role={props.role}
        tabIndex={props.tabIndex}
        aria-label={props.ariaLabel}
        aria-hidden={props.ariaHidden}
      >
        {props.icon && props.iconSide === "left" && (
          <span className="brand-button__icon">{props.icon}</span>
        )}
        {!!props.text && (
          <span className="brand-button__text">{props.text}</span>
        )}
        {props.icon && props.iconSide === "right" && (
          <span className="brand-button__icon">{props.icon}</span>
        )}
      </BodyElement>
    )
  }
}

BrandButton.propTypes = {
  className: PropTypes.string,
  color: PropTypes.oneOf([
    "primary",
    "primary-light",
    "secondary",
    "tertiary",
    "transparent",
  ]),
  disabled: PropTypes.bool,
  btnStyle: PropTypes.oneOf(["fill", "outline", "shadow"]),
  size: PropTypes.oneOf(["sm", "md", "nav", "utility", "utility-small"]),
  icon: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  text: PropTypes.string,
  onClick: PropTypes.func,
  onKeyDown: PropTypes.func,
  type: PropTypes.string,
  iconSide: PropTypes.oneOf(["left", "right"]),
  tabIndex: PropTypes.string,
  role: PropTypes.string,
  ariaLabel: PropTypes.string,
  ariaHidden: PropTypes.bool,
  element: PropTypes.oneOf(["span", "button"]),
}
BrandButton.defaultProps = {
  color: "primary",
  btnStyle: "fill",
  size: "md",
  iconSide: "left",
  element: "button",
}

export default BrandButton
