import React, { useState } from "react"
import PropTypes from "prop-types"
import addToMailchimp from "gatsby-plugin-mailchimp"

import BrandButton from "@components/common/BrandButton"
import BodyText from "@components/common/BodyText"

import language from "@language"

import "./style.scss"
import FormInput from "../FormInput"

const EmailForm = props => {
  const [isLoading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [succeeded, setSuccess] = useState(false)

  const emailInputRef = React.createRef()
  const honeypotRef = React.createRef()

  const handleSubmit = async e => {
    e.preventDefault()
    setError(null)
    const emailValue = emailInputRef.current.value
    const honeypotValue = honeypotRef.current.value

    if (emailValue && !honeypotValue) {
      setLoading(true)

      addToMailchimp(emailValue)
        .then(data => {
          if (data.result === "error") {
            setError(language[props.language].error_occured)
          } else {
            setSuccess(true)
          }
        })
        .catch(err => {
          setError(language[props.language].error_occured)
          console.log(err)
        })

      setLoading(false)
    }
  }

  const buttonText = () => {
    if (isLoading) {
      return `${language[props.language].submitting}...`
    } else if (succeeded) {
      return `${language[props.language].thank_you}`
    } else {
      return language[props.language].submit
    }
  }

  return (
    <div className="email-form">
      <form className="email-form__form" onSubmit={handleSubmit}>
        <label className="d-none" htmlFor="newsletter_email_input">
          {language[props.language].email}
        </label>
        <FormInput
          inputRef={emailInputRef}
          className="email-form__input"
          name="email"
          id="newsletter_email_input"
          inputType="dark"
          placeholder={language[props.language].email}
        />
        <FormInput
          name="name"
          type="text"
          inputRef={honeypotRef}
          style={{ position: "absolute", left: "-5000px" }}
          ariaHidden={true}
          tabIndex="-1"
        />
        <BrandButton
          className="email-form__submit"
          disabled={isLoading || succeeded}
          color="tertiary"
          size="sm"
          text={buttonText()}
          type="submit"
        />
      </form>
      {error && (
        <BodyText
          className="email-form__notification"
          size="md"
          color="light"
          text={error}
        />
      )}
      {succeeded && (
        <BodyText
          className="email-form__notification"
          size="md"
          color="light"
          text={language[props.language].email_added}
        />
      )}
    </div>
  )
}

EmailForm.propTypes = {
  language: PropTypes.string,
}
EmailForm.defaultProps = {
  language: "es",
}

export default EmailForm
