/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useState, useRef } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import language from "@language"
import Header from "@components/Header"
import Footer from "@components/Footer"

import classnames from "classnames"

import "./layout.scss"

import NavMenu from "./NavMenu"
import BodyText from "./common/BodyText"

const Layout = props => {
  const [navMenuIsOpen, setNavMenuOpen] = useState(false)
  const layoutRef = useRef(null)
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div
      id="top"
      ref={layoutRef}
      className={classnames("layout", props.className)}
    >
      <a href="#main" className="sr-only sr-only-focusable skip-to">
        <BodyText size="md" color="dark">
          {language[props.language].skip_to_main_content}
        </BodyText>
      </a>
      <NavMenu setNavMenuOpen={setNavMenuOpen} isOpen={navMenuIsOpen} />
      <Header
        isHomepage={props.isHomepage}
        layoutRef={layoutRef}
        setNavMenuOpen={setNavMenuOpen}
        siteTitle={data.site.siteMetadata.title}
      />
      <div id="main" className="layout__container">
        <main className="layout__page-wrap">{props.children}</main>
        <a
          onClick={() => setNavMenuOpen(false)}
          href="#top"
          className="sr-only sr-only-focusable skip-to"
        >
          <BodyText size="md" color="dark">
            {language[props.language].skip_to_top}
          </BodyText>
        </a>
        <Footer />
      </div>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  isHomepage: PropTypes.bool,
}

Layout.defaultProps = {
  isHomepage: false,
  language: "es",
}

export default Layout
