import React, {useState} from "react"
import { Link } from "gatsby"


import "./style.scss"


const PortalButton = props => {
  const [isOpen, toggle] = useState(false);

  return (
    <div className={`portal-button ${isOpen ? 'open' : ''}`}>
      <button class="trigger" onClick={() => toggle(!isOpen)}>
        Portal de Pacientes
      </button>
      <ul>
        <li>
          <Link to="https://mirecord.dchpr.com/Phm-PhmHome.HomePage.WR.mthr?hcis=DTCWEB.LIVE&application=phm" target="_blank">Versión Normal</Link>
        </li>
        <li>
          <Link to="https://mirecord.dchpr.com/Phm-PhmAltPage.HomePage.WR.mthr?hcis=DTCWEB.LIVE&application=phm" target="_blank">Versión ADA</Link>
        </li>
      </ul>
    </div>
  )
}


export default PortalButton
