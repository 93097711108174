import React from "react"
import PropTypes from "prop-types"

import classnames from "classnames"

import "./style.scss"

const validElements = ["p", "span", "h4", "li", "blockquote"]

const BodyText = props => {
  const validBodyLevels = validElements

  const safeBody = props.element ? props.element.toLowerCase() : ""
  const BodyElement = validBodyLevels.includes(safeBody) ? safeBody : "p"

  return (
    <BodyElement
      className={classnames(
        "body-text",
        `body-text--${props.size}`,
        `body-text--${props.color}`,
        { "font-weight--bold": props.fontWeight === "bold" },
        { "font-weight--normal": props.fontWeight === "normal" },
        { "font-weight--300": props.fontWeight === "300" },
        props.className
      )}
      style={props.style}
    >
      {props.text || props.children}
    </BodyElement>
  )
}

BodyText.propTypes = {
  className: PropTypes.string,
  color: PropTypes.oneOf([
    "dark",
    "dark-gray",
    "light-gray",
    "light",
    "primary",
    "primary-dark",
    "error",
  ]),
  element: PropTypes.oneOf(validElements),
  text: PropTypes.string,
  size: PropTypes.oneOf([
    "xxxl",
    "xxl",
    "xl",
    "lg",
    "lg2",
    "md",
    "md2",
    "sm",
    "xs",
  ]),
  style: PropTypes.object,
  fontWeight: PropTypes.oneOf(["300", "normal", "bold"]),
}
BodyText.defaultProps = {
  color: "dark",
  element: "p",
  size: "xl",
}

export default BodyText
