const es = {
  about_the_position: "Sobre la posición",
  admissions_telephone: "Departamento de admisiones",
  address: "Dirección",
  all_staff: "Todo el personal",
  application: "Aplicación",
  apply_here: "Aplíca aqui",
  archive: "Archivo",
  author: "Autor",
  back_to_directory: "Volver al directorio",
  back_to_jobs: "Volver a empleos",
  biography: "Biografía",
  category: "Categoría",
  certifications: "Certificaciones",
  centers_where_i_work: "Centros donde trabajo",
  charge_master_guide: "Charge Master",
  close_menu: "Cerrar el menú",
  compensation: "Compensación",
  conditions_i_treat: "Condiciones que trato",
  contact: "Contacto",
  contact_information: "Información de Contacto",
  copy_the_link: "Copia el enlace",
  date: "Fecha",
  direction: "Dirección",
  directions: "Direcciones",
  department: "Departamento",
  departments: "Departamentos",
  doctors_center_clinics: "Doctors' Center Clinics",
  download_the_guide: "Descarga el guía",
  education: "Educación",
  estimated_charges_guide: "Cargos estimados",
  email: "Correo electrónico",
  email_added: "Su correo electrónico ha sido agregado.",
  error_occured: "Lo siento, hay un error.",
  fellowships: "Fellowships",
  filters: "Filtros",
  hello: "Hola",
  help: "Ayuda",
  health_for_life: "Salud para vivir",
  hospital_guide: "Guía del hospital",
  insitution: "Institución",
  jump_to_a_section: "Brinca a una sección",
  laboratory_hours: "Horas de laboratorio",
  learn_more: "Aprende más",
  last_name: "Apellido",
  link_copied: "Enlace copiado",
  location: "Localización",
  locations: "Localizaciones",
  main_telephone: "Cuadro telefónico",
  message: "Mensaje",
  message_sent: "¡Mensaje enviado!",
  mission: "Misión",
  more_info: "Más info",
  name: "Nombre",
  navigation_links: "Enlaces de navegación",
  news: "Noticias",
  next_news: "Próxima Noticia",
  no_results: "No hay resultados",
  no_options: "Sin opciones",
  office_hours: "Horas de oficina",
  open_menu: "Abre el menú",
  order_by: "Ordena por",
  page_not_found: "Página no encontrada",
  previous_news: "Previa Noticia",
  procedures_i_do: "Procedimientos que hago",
  qualifications: "Cualificaciones",
  read_more: "Leer más",
  recent_news: "Noticias Recientes",
  residencies: "Residencias",
  schedule: "Horario",
  search_by_name: "Busqueda por nombre",
  send_message: "Enviar mensaje",
  skip_to_main_content: "Saltar al contenido principal",
  skip_to_top: "Saltar al principio",
  specialty: "Especialidad",
  specialties: "Especialidades",
  specialized_clinics: "Clínicas Especializadas",
  specialized_centers: "Centros Especializados",
  subject: "Asunto",
  start: "Comienzo",
  submit: "Añadir",
  submitting: "Añadiendo",
  telephone_number: "Número de teléfono",
  telephone: "Teléfono",
  telephones: "Teléfonos",
  thank_you: "Gracias",
  topic: "Tema",
  timeline: "Cronología Doctors'",
  title: "Título",
  values: "Valores",
  view_more: "Ver más",
  view_their_profile: "Ver su perfil",
  vision: "Visión",
  visit_dch: "Visítanos",
  write_your_address: "Escribe tu dirección",
  sentences: {
    fullDateFormat: "D [de] MMMM [de] YYYY",
    shortDateFormat: "MMMM D, YYYY",
    showingXResults: number => {
      const noun = number === 1 ? "resultado" : "resultados"
      return `Mostrando ${number} ${noun}`
    },
  },
}

export default es
